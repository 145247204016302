import React, { Component } from 'react';
import {
  ItemFilter,
  SinglePageLayout,
  HorizontalSlider,
} from '../../components';
import './Talent.scss';
import { shuffle } from '../../helper/helper';
import { parse } from 'query-string';
import { navigate } from '@reach/router';

const addQuerystring = (url, filter) =>
  `${url}${filter === 'all' ? '' : `?category=${filter}`}`;

const TalentsContent = ({ sliderData }) => {
  return (
    <div className="talent__slideShow">
      <HorizontalSlider data={sliderData()} largeHeader />
    </div>
  );
};

const talentCategories = [
  'all',
  'director',
  'directorOfPhotography',
  'editor',
  'composer',
  'motionDesigner',
];

class Talents extends Component {
  constructor(props) {
    super(props);

    this.search = parse(props.location.search).category;

    this.data = !this.search
      ? shuffle(props.data.talents)
      : shuffle(
          props.data.talents.filter(item => item.category === this.search)
        );

    this.state = {
      filter: this.search || 'all',
      visibleItems: this.data.talents,
    };
  }

  componentDidMount() {
    if (!this.state.visibleItems) {
      this.handleFilterChange(this.search || 'all');
    }
  }

  handleFilterChange = filter => {
    this.setState({
      filter,
      visibleItems:
        filter === 'all'
          ? this.props.data.talents
          : this.props.data.talents.filter(item => item.category === filter),
    });

    navigate(addQuerystring(this.props.path, filter));
  };

  renderSliderData(visibleItems) {
    const { path } = this.props;

    const {
      text: { home },
    } = this.props.pageContext;

    return visibleItems.map(step => ({
      image: step.image,
      title: step.talentName,
      link: {
        target: addQuerystring(
          `${path}/${step.sluggedName}/`,
          this.state.filter
        ),
        label: home.openProfile,
        state: {
          /*
           * pass the sorted & filtered talents to correctly navigate
           * next and previous talents in DetailPageFooterNav
           */
          filteredItems: visibleItems,
        },
      },
    }));
  }

  render() {
    const {
      text: { talents },
      footerData,
    } = this.props.pageContext;
    return (
      <SinglePageLayout>
        <ItemFilter
          text={talents}
          filters={talentCategories}
          setFilter={this.handleFilterChange}
          activeFilter={this.state.activeFilter}
        />
        {this.state.visibleItems && (
          <TalentsContent
            sliderData={() => this.renderSliderData(this.state.visibleItems)}
          />
        )}
      </SinglePageLayout>
    );
  }
}

export default Talents;
