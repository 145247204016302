import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Layout/Layout';
import Talents from './Talents';

export default props => {
  const data = {
    talents: props.data.talents.nodes,
  };
  return (
    <Layout>
      <Talents {...props} data={data} />
    </Layout>
  );
};

export const query = graphql`
  query($locale: String!) {
    talents: allContentfulTalentDetailPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        sluggedName
        talentName
        category
        image {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
